<template>
  <div class="px-main --dark">
    <v-col cols="12" lg="10" class="pa-0">
      <h1 class="page-title-main py-4">
        {{facilityName}}/{{ nameRoomType }}
      </h1>

      <template>
        <v-tabs v-model="tab" class="mb-10 d-inline-flex t-tab-custom">
          <v-tab class="px-8">{{
            $t("facility.settings.roomTypeInformation")
          }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="bg--secondary">
          <v-tab-item class="mb-10 bg--white">
          <v-form ref="form">
              <div class="pa-5">
              <div class="t-item mt-0 custom-text">
                <label class="">{{ $t("facility.settings.roomTypeID") }}</label>
                {{ roomTypeId }}
              </div>

              <div class="t-item custom-text">
                <label class="mb-7">{{
                  $t("facility.settings.roomTypeNameManagement")
                }}</label>
                <v-text-field
                  v-model="roomType.name"
                  dense
                   :rules="rulesRoomType.name"
                  outlined
                  :disabled="!checkPerUser"
                  class="pa-0 medium-input"
                ></v-text-field>
              </div>

              <div class="t-item custom-text">
                <label class="mb-7">{{
                  $t("facility.settings.managementScreenDisplayOrder")
                }}</label>
                <v-select
                 :rules="[() => !!roomType.order ||$t('facility.rentalList.rules.required')]"
                  :items="listOrderScreen"
                  v-model="roomType.order"
                  outlined
                  dense
                  :disabled="!checkPerUser"
                  class="medium-input"
                ></v-select>
              </div>

              <div class="t-item custom-text" style="margin-bottom: 20px !important">
                <label>{{
                  $t("facility.settings.managementScreenColor")
                }}</label>
                <v-text-field
                  v-model="roomType.backgroundColor"
                  hide-details
                  dense
                  outlined
                  :disabled="!checkPerUser"
                  class="ma-0 pa-0 medium-input"
                >
                  <template v-slot:append>
                    <v-menu
                      v-model="colorMenu"
                      top
                      nudge-bottom="105"
                      nudge-left="16"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on }">
                        <div :style="swatchStyle" v-on="on" />
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker  :disabled="!checkPerUser" @input="getChangeColor($event)" v-model="color"  />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </template>
                </v-text-field>
              </div>
              <div class="t-item custom-text">
                <label class="mb-7">{{ $t("facility.settings.maxNumOfPeople") }}</label>
                <v-text-field type="number"
                  v-model="roomType.maxOccupancy"
                   :rules="[() => !!roomType.maxOccupancy ||$t('facility.rentalList.rules.required'),
                    () => roomType.maxOccupancy > 0 ||$t('rules.isInvalid')]"
                  dense
                  outlined
                  :disabled="!checkPerUser"
                  class="pa-0 medium-input"
                ></v-text-field>
              </div>

              <div class="t-item custom-text">
                <label class="mb-7">{{ $t("facility.settings.numOfCoSleeping") }}</label>
                <v-text-field type="number"
                  v-model="roomType.bedShareMaxOccupancy"
                  dense
                  outlined
                  :disabled="!checkPerUser"
                  class="pa-0 medium-input"
                ></v-text-field>
              </div>

              <div class="t-item mb-15 custom-text">
                <label class="mb-7">{{
                  $t("facility.settings.roomTypeIDCollaboration")
                }}</label>
                <v-text-field
                @change="changeRakutsuId()"
                 :rules="rulesRoomType.rakutsuId"
                  v-model="roomTypeRakutsuId"
                  dense
                  outlined
                  :disabled="!checkPerUser"
                  class="pa-0 medium-input"
                ></v-text-field>
              </div>

              <v-divider></v-divider>

              <div class="t-item custom-text">
                <label class="align-self-start">{{
                  $t("facility.settings.deleteThisRoomType")
                }}</label>
                <div>
                  {{ $t("facility.settings.deleteRoomTypeNotice") }}
                  <br />
                  <v-btn :disabled="!checkPerUser" outlined class="btn--pink-ounlined mt-1" @click="checkPerUser ? deleteRoomType() : ''">{{
                    $t("buttons.delete")
                  }}</v-btn>
                </div>
              </div>

              <div class="text-right mt-5">
                <v-btn color="#004F8B" class=" mr-3" @click="checkPerUser ? saveAndUpdate(): ''" :disabled="!checkPerUser">
                  <span style="color: #fff">{{
                  $t("buttons.saveAndUpdate")
                }}</span>
                </v-btn>
                <v-btn color="#004F8B" @click="checkPerUser ? saveAndClose() : ''" :disabled="!checkPerUser">
                  <span style="color: #fff">{{
                  $t("buttons.saveAndClose")
                }}</span>
                </v-btn>
              </div>
            </div>
          </v-form>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </v-col>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { FACILITY_LIST } from '@/api/graphql/facility-settings'
import { mapMutations, mapActions } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'

export default {
  name: 'RoomTypeDetail',
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      facilityName: '',
      maxInt: 256,
      type: 'hex',
      hex: '#FF00FF',
      roomType: [],
      roomTypeId: this.$route.query.id,
      tab: 1,
      roomTypeName: 'ペット',
      listOrderScreen: [1, 2, 3, 4, 5, 6],
      orderScreen: 1,
      maxPeople: 4,
      numCoSleeping: 0,
      idCollab: 1,
      roomTypeRakutsuId: '',
      nameRoomType: '',
      facilityId: null,
      rulesRoomType: {
        name: [v => !!v || this.$t('rules.isRequired'),
          v => v?.length < 255 || this.$t('rules.isInvalid')
        ],
        rakutsuId: [
          v => v?.length < 255 || this.$t('rules.isInvalid')
        ]
      },
      // --------------- color picker ------
      colorPicker: '#1873d9',
      colorMenu: false,
      selectColor: '#1873d9'
    }
  },
  mounted () {
    this.getRoomType()
    this.getListOrder()
  },
  methods: {
    ...mapMutations({ setFacilityRoomType: 'setFacilityRoomType' }),
    ...mapActions({
      actionUpdate: 'updateRoomType',
      actionDelete: 'deleteRoomType',
      actionUpdateBack: 'updateRoomTypeBack'
    }),

    changeRakutsuId () {
      this.roomType.rakutsuId = this.roomTypeRakutsuId
    },

    async getFacility () {
      await this.$apollo.query({
        query: gql`${FACILITY_LIST}`
      }).then((data) => {
        const facilityItem = (data.data.facilityList).find((item) => item.id === this.facilityId)
        this.facilityName = facilityItem.name
        document.title = facilityItem.name + '/' + this.nameRoomType || 'PMS'
      }).catch((error) => {
        console.error(error)
      })
    },

    async getRoomType () {
      await this.$apollo.query({
        query: gql`query ($id: Int!){
          roomType(id: $id) {
            name id order maxOccupancy bedShareMaxOccupancy backgroundColor rakutsuId facilityId
          }
        }`,
        variables: {
          id: parseInt(this.$route.query.id)
        }
      }).then((data) => {
        this.roomType = data.data.roomType
        this.selectColor = '#' + this.roomType.backgroundColor
        this.nameRoomType = this.roomType.name
        this.facilityId = this.roomType.facilityId
        if (this.roomType.rakutsuId == null) {
          this.roomTypeRakutsuId = ''
        } else {
          this.roomTypeRakutsuId = this.roomType.rakutsuId
        }
        this.getFacility()
      }).catch((error) => {
        console.error(error)
      })
      this.$refs.form.resetValidation()
    },
    getListOrder () {
      for (let i = 1; i <= 100; i++) {
        this.listOrderScreen.push(i)
      }
    },
    getChangeColor (event) {
      this.selectColor = event
      this.roomType.backgroundColor = event.slice(1)
    },
    saveAndUpdate () {
      if (this.$refs.form.validate()) {
        const facilityRoomType = {
          name: this.roomType.name,
          order: this.roomType.order,
          backgroundColor: this.roomType.backgroundColor,
          maxOccupancy: this.roomType.maxOccupancy,
          bedShareMaxOccupancy: this.roomType.bedShareMaxOccupancy,
          rakutsuId: this.roomType.rakutsuId,
          facilityId: this.facilityId
        }
        this.setFacilityRoomType({ facilityRoomType: facilityRoomType })
        this.actionUpdate()
        this.$refs.form.resetValidation()
      }
    },
    saveAndClose () {
      if (this.$refs.form.validate()) {
        const facilityRoomType = {
          name: this.roomType.name,
          order: this.roomType.order,
          backgroundColor: this.roomType.backgroundColor,
          maxOccupancy: this.roomType.maxOccupancy,
          bedShareMaxOccupancy: this.roomType.bedShareMaxOccupancy,
          rakutsuId: this.roomType.rakutsuId
        }
        this.setFacilityRoomType({ facilityRoomType: facilityRoomType })
        this.actionUpdateBack()
        this.$refs.form.resetValidation()
      }
    },
    deleteRoomType () {
      const facilityRoomType = {
        id: this.roomType.id,
        facilityId: this.roomType.facilityId
      }
      this.setFacilityRoomType({ facilityRoomType: facilityRoomType })
      this.actionDelete()
    }
  },
  computed: {
    swatchStyle () {
      const { colorMenu } = this
      return {
        backgroundColor: this.selectColor,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        marginTop: '-3px',
        borderRadius: colorMenu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    },
    color: {
      get () {
        return this[this.type]
      },
      set (v) {
        this[this.type] = v
      }
    },
    showColor () {
      if (typeof this.color === 'string') return this.color

      return JSON.stringify(Object.keys(this.color).reduce((color, key) => {
        color[key] = Number(this.color[key].toFixed(2))
        return color
      }, {}), null, 2)
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-text {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
::v-deep {
  .v-select__slot{
    .v-select__selection--comma{
      font-size: 16px !important;
      color: #666666 !important
    }
  }
  input {
    font-size: 16px !important;
    color: #666666 !important
  }
  .v-divider {
    margin: 25px 0;
  }
  .v-file-input {
    .v-input__append-inner {
      display: none;
    }
  }
}
.custom-text {
  font-size: 16px !important;
  color: #666666 !important
}
</style>
